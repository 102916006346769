.App {
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
  color: black;
}

.Container {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: calc(100% - 70px);
  width: 100%;
  align-items: center;
}

.Flex {
  display: flex;
  flex: auto;
}

.responsive {
  width: 100%;
  height: auto;
}

table, td, th {
  border: 1px solid black;
  border-collapse: collapse;
}

.Navbar {
  /* background-color: #9fa8da; */
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.NavbarTitle {
  margin: 0;
  color: black;
  font-size: 40px;
  padding-left: 10px;
}

.NavbarText {
  font-size: 25px;
  padding-right: 30px;
  color: black;
}

.AboutContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0px 25px;
}

.InlineLink {
  display: inline;
}

.DownloadButtonContainer {
  display: flex;
  flex-direction: row;
  flex: auto;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.ClickableText {
  cursor: pointer;
}

.VisualizationContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
}

.ChartInstructionContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.ChartInstructionText {
  font-size: small;
}

.SideBarContainer {
  width: 100%;
  flex-shrink: 0;
}

.CreditsText {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 90%;
  text-align: left;
  margin: auto;
  font-size: small;
}

.ChartContainer {
  width: 100%;
  height: auto;
  max-height: 400px;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  display: block;
  box-sizing: border-box;
}

.ChartCanvas {
  background-color: #f9f9f9;
  max-height: 100%;
}

.SliderContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.SliderWrapper {
  margin-top: 5px;
}

.SliderText {
  text-align: left;
  margin: 5px 0px 0px 0px;
  padding: 0px;
}

.SliderInput {
  width: 3.5rem;
  margin-left: 20px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.DropdownContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 0px 5px 5px 5px;
}

/* Remove arrows from numeric inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 450px) {
  .ChartContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 600px) {
  .ChartContainer {
    padding-left: 24px;
    padding-right: 24px;
    min-height: 450px;
    max-height: 450px;
  }
}

@media (min-width: 950px) {
  .ChartInstructionContainer {
    width: calc(100% - 384px);
    text-align: left;
  }

  .ChartInstructionText {
    padding-left: 16px;
  }

  .ChartContainer {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ButtonContainer {
    padding: 0px;
  }

  .VisualizationContainer {
    max-width: 1600px;
    flex-direction: row;
    justify-content: center;
  }

  .SideBarContainer {
    font-size: 0.85em;
    width: 352px;
    padding: 16px 32px 16px 0px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-height: 750px) {
  .ChartContainer {
    min-height: 650px;
    max-height: 650px;
  }
}
